import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import RadioGroup from "../../../components/forms/RadioGroup";
import { setValueConfig } from "../../FormWrapper";
import { CaseUiData } from "../../schema/CaseUiSchema";
import { CaseSchemaOptions, SlideOptions } from "../../schema/FormAnswers";

interface SlidesProps {
  forceP53?: boolean;
}

const Slides = ({ forceP53 }: SlidesProps): JSX.Element => {
  const { register, formState, setValue } = useFormContext<CaseUiData>();

  useEffect(() => {
    // For surveillance cases which always have a p53 stain
    if (forceP53) {
      setValue("slides", SlideOptions.HE_TFF3_P53, setValueConfig);
    }
  }, [forceP53, setValue]);

  if (forceP53) {
    return (
      <input
        type="hidden"
        id="slides"
        {...register("slides", { value: SlideOptions.HE_TFF3_P53 })}
      />
    );
  } else {
    return (
      <RadioGroup
        id="slides"
        label="Slides"
        options={CaseSchemaOptions.slides}
        error={formState.errors.slides}
        {...register("slides")}
      />
    );
  }
};

export default Slides;
