import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import LabelledIcon from "../components/atoms/LabelledIcon";
import ListCountHeading from "../components/atoms/ListCountHeading";
import CaseCard from "../components/cases/CaseCard";
import CaseSearch from "../components/cases/CaseSearch";
import PageHeader from "../components/pages/PageHeader";
import NoResults from "../components/search/NoResults";
import Pagination from "../components/search/Pagination";
import SearchTags from "../components/search/SearchTags";
import SortIndicator from "../components/search/SortIndicator";
import { SITE_TITLE } from "../helpers/strings";
import useDebounce from "../hooks/useDebounce";
import { AppDispatch, RootState } from "../store";
import { fetchCases, updatePageNumber } from "../store/caseListSlice";
import ErrorPage from "./ErrorPage";

export const TEST_ID_CASE_LIST_TITLE = "CaseListTitle";
export const TEST_ID_NEW_CASE_BUTTON = "NewCaseButton";

const CaseList = (): JSX.Element => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const { status, error, results, page, total, totalPages, searchParameters } =
    useSelector((state: RootState) => state.caseList);

  const pageTitle = "Cases";
  const debouncedPageNumber = useDebounce(page);
  const debouncedSearchParameters = useDebounce(searchParameters);

  useEffect(() => {
    document.title = `${pageTitle} | ${SITE_TITLE}`;
    dispatch(fetchCases());
  }, [dispatch, debouncedPageNumber, debouncedSearchParameters]);

  const isError = status === "failed";
  const isLoading = status === "pending";
  const isLoaded = status === "succeeded";
  const noResults = isLoaded && total === 0;
  const showResults = (isLoading || isLoaded) && total > 0;

  const handlePageChange = (page: number): void => {
    dispatch(updatePageNumber(page));
  };

  if (isError) {
    return <ErrorPage title={error?.error} subtitle={error?.msg} />;
  }

  return (
    <div className="container">
      <PageHeader title={pageTitle} subtitle="Find and manage cases" />
      <div className="columns is-variable is-4 is-align-items-flex-start">
        <div className="column is-one-quarter is-sticky">
          <CaseSearch />
        </div>
        <div className="column is-three-quarters">
          <div className="is-flex is-justify-content-space-between">
            <ListCountHeading
              noun="case"
              count={total}
              isLoading={isLoading}
              testId={TEST_ID_CASE_LIST_TITLE}
            />
            <Link
              to="/"
              className="button is-primary"
              data-testid={TEST_ID_NEW_CASE_BUTTON}
            >
              <LabelledIcon icon={faPlus} label="New case" />
            </Link>
          </div>
          <SearchTags />
          {noResults && <NoResults />}
          {showResults && (
            <>
              <div className="is-flex is-justify-content-space-between is-align-items-center mb-4">
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
                <SortIndicator />
              </div>
              <ul className="mb-4">
                {results.map(({ caseData, caseState, permissions: { canEditCase } }) => (
                  <CaseCard
                    key={caseData.labNumber}
                    item={caseData}
                    caseState={caseState}
                    canEditCase={canEditCase}
                  />
                ))}
              </ul>
              <Pagination
                page={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CaseList;
