import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import Fieldset from "../../components/forms/Fieldset";
import { RequestFormProps } from "../FormComponent";
import { BarrettsDetails, DeviceDetails, LabUseOnly, ReportRecipient } from "../blocks";
import { ProcedureDetails } from "../blocks/deprecated";
import { Best4ParticipantId, Best4StudyArm } from "../fields/best4";
import { HasAdditionalClinicalInformation } from "../fields/clinical";
import {
  PatientDateOfBirth,
  PatientIdentifier,
  PatientInitials,
  PatientSex,
} from "../fields/patient";
import useFieldModifiers from "../hooks/useFieldModifiers";
import { CaseUiData } from "../schema/CaseUiSchema";
import { Best4SurveillanceStudyArms } from "../schema/RequestForms";

const TRF_4_Version_1 = ({
  formMode,
  formLocked,
  limsOptions: { specimens, clinicians, caseOrigins, consultants },
}: RequestFormProps): JSX.Element => {
  const { register, unregister } = useFormContext<CaseUiData>();
  const { clearPatientIdentifierIfNotProvided, setPatientNameFromInitials } =
    useFieldModifiers();

  useEffect(() => {
    // Discard any fields in form state which aren't used in this form
    unregister([
      "confidentSpongeReachedStomach",
      "useRecordNumberForPatientName",
      "useClinicianForProcedureSite",
      "patientIdentifierAlternative",
      "patientIdentifierNotProvided",
      "hasRefluxSymptoms",
      "hasEoEDiagnosis",
      "deviceIdForFailedAttempt",
      "reasonsForFailedAttempt",
    ]);
    clearPatientIdentifierIfNotProvided();
    setPatientNameFromInitials();
  }, [unregister, clearPatientIdentifierIfNotProvided, setPatientNameFromInitials]);

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <DeviceDetails disabled={formLocked} specimens={specimens} />
        </div>
        <div className="tile is-parent">
          <ReportRecipient
            disabled={formLocked}
            clinicians={clinicians}
            caseOrigins={caseOrigins}
          />
        </div>
      </div>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <Fieldset
            title="Participant details"
            className="tile is-child"
            disabled={formLocked}
          >
            <div className="columns">
              <div className="column is-8">
                <PatientIdentifier />
              </div>
              <div className="column is-4">
                <PatientInitials
                  help="2 letters only"
                  onChange={setPatientNameFromInitials}
                />
                <input
                  type="hidden"
                  id="patientFirstName"
                  {...register("patientFirstName")}
                />
                <input
                  type="hidden"
                  id="patientSurname"
                  {...register("patientSurname")}
                />
              </div>
            </div>
            <div className="columns">
              <div className="column is-8">
                <PatientDateOfBirth />
              </div>
              <div className="column is-4">
                <PatientSex />
              </div>
            </div>
          </Fieldset>
        </div>
        <div className="tile is-parent">
          <Fieldset title="BEST4" className="tile is-child" disabled={formLocked}>
            <Best4ParticipantId help="e.g. SUR-ABC-123" />
            <Best4StudyArm disabled={formLocked} studyArms={Best4SurveillanceStudyArms} />
          </Fieldset>
        </div>
      </div>

      <Fieldset title="Clinical information" disabled={formLocked}>
        <BarrettsDetails disabled={formLocked} />
        <HasAdditionalClinicalInformation />
      </Fieldset>

      <ProcedureDetails formMode={formMode} formLocked={formLocked} />

      <LabUseOnly
        forceP53
        formMode={formMode}
        disabled={formLocked}
        consultants={consultants}
      />
    </>
  );
};

export default TRF_4_Version_1;
