import classNames from "classnames";
import { format } from "date-fns";
import { filter, find, includes } from "lodash";
import { useSnackbar } from "notistack";
import React, { FormEvent, MouseEvent, useState } from "react";

import {
  Special,
  SpecialState,
  SpecialTransition,
  SpecialTransitionOperation,
} from "../../schemas/SpecialRequestSchema";
import { dataService } from "../../services/data.service";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogError,
  DialogSubtitle,
  DialogTitle,
} from "../dialogs";
import RadioOptionWithDescription from "../forms/RadioOptionWithDescription";
import SlideLinks from "./SlideLinks";

export const TEST_ID_DIALOG_UPDATE_REQUEST_TITLE = "DialogUpdateRequestTitle";
export const TEST_ID_DIALOG_UPDATE_REQUEST_SUBTITLE = "DialogUpdateRequestSubtitle";
export const TEST_ID_DIALOG_UPDATE_REQUEST_SUMMARY = "DialogUpdateRequestSummary";
export const TEST_ID_DIALOG_UPDATE_REQUEST_CONFIRM_BUTTON =
  "DialogUpdateRequestConfirmButton";

interface SpecialRequestDialogProps {
  special: Special;
  closeDialog: (e?: MouseEvent) => void;
}

const SpecialRequestDialog = ({
  special: {
    id: requestId,
    labNumber,
    requestedByName,
    dateRequested,
    details,
    state: currentState,
  },
  closeDialog,
}: SpecialRequestDialogProps): JSX.Element => {
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [selectedTransition, setSelectedTransition] = useState<SpecialTransition>();

  const { enqueueSnackbar } = useSnackbar();

  const byRequester: string = requestedByName ? `by ${requestedByName}` : "";
  const requestDate: string = format(dateRequested, "d MMM yyyy"); // eg. 19 Jun 2024

  const { NEW, ORDERED, COMPLETE, CANCELLED } = SpecialState;
  const allTransitions: SpecialTransitionOperation[] = [
    {
      transition: ORDERED,
      allowedWhen: [NEW],
      request: async () => await dataService.updateSpecial(requestId, ORDERED),
      label: "Mark as ordered",
      description: "Has an order form been created for this request?",
      successMessage: `Marked request for ${labNumber} as ordered`,
    },
    {
      transition: COMPLETE,
      allowedWhen: [ORDERED],
      request: async () => await dataService.updateSpecial(requestId, COMPLETE),
      label: "Mark as completed",
      description: "Are all of the expected slide images present?",
      successMessage: `Marked request for ${labNumber} as completed`,
    },
    {
      transition: CANCELLED,
      allowedWhen: [NEW, ORDERED],
      request: async () => await dataService.updateSpecial(requestId, CANCELLED),
      label: "Cancel request",
      description:
        "Permanently cancel this special request. This action cannot be undone.",
      successMessage: `Cancelled request for ${labNumber}`,
    },
  ];

  const allowedTransitions: SpecialTransitionOperation[] = filter(
    allTransitions,
    ({ allowedWhen }) => includes(allowedWhen, currentState)
  );

  const handleClose = () => !busy && closeDialog();

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    setError("");
    setBusy(true);
    const operation = find(allowedTransitions, ["transition", selectedTransition]);
    if (operation) {
      const { data: success, error } = await operation.request();
      if (success) {
        enqueueSnackbar(operation.successMessage, { variant: "success" });
        closeDialog();
      } else {
        setError(error?.msg ?? "Unexpected error. Please try again later.");
        setBusy(false);
      }
    }
  };

  return (
    <Dialog onClose={handleClose}>
      <DialogTitle testId={TEST_ID_DIALOG_UPDATE_REQUEST_TITLE}>
        Update special request
      </DialogTitle>
      <DialogSubtitle testId={TEST_ID_DIALOG_UPDATE_REQUEST_SUBTITLE}>
        {details}
      </DialogSubtitle>
      <DialogContent>
        <p className="mb-4" data-testid={TEST_ID_DIALOG_UPDATE_REQUEST_SUMMARY}>
          Requested {byRequester} for {labNumber} on {requestDate}.
        </p>
        <form onSubmit={handleSubmit}>
          {allowedTransitions.map(({ transition, label, description }) => {
            // Only show slide list when it's needed for pre-completion checks
            const showSlideList: boolean = transition === COMPLETE;
            return (
              <React.Fragment key={transition}>
                <RadioOptionWithDescription
                  id={transition}
                  name="selectedTransition"
                  value={transition}
                  label={label}
                  description={description}
                  selectedValue={selectedTransition}
                  noMargin={showSlideList}
                  disabled={busy}
                  onChange={(e) =>
                    setSelectedTransition(e.target.value as SpecialTransition)
                  }
                />
                {showSlideList && <SlideLinks labNumber={labNumber} />}
              </React.Fragment>
            );
          })}

          <DialogActions>
            <button
              type="submit"
              disabled={busy || !selectedTransition}
              className={classNames("button is-primary", { "is-loading": busy })}
              data-testid={TEST_ID_DIALOG_UPDATE_REQUEST_CONFIRM_BUTTON}
            >
              Confirm
            </button>
            <button
              type="button"
              disabled={busy}
              className="button is-light"
              onClick={handleClose}
            >
              Cancel
            </button>
          </DialogActions>
          <DialogError>{error}</DialogError>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SpecialRequestDialog;
