import React from "react";

import CheckboxGroup from "../../../components/forms/CheckboxGroup";
import { CaseSchemaOptions } from "../../schema/FormAnswers";

interface ReasonsForFailedAttemptProps {
  disabled: boolean;
}

const ReasonsForFailedAttempt = ({
  disabled,
}: ReasonsForFailedAttemptProps): JSX.Element => {
  return (
    <CheckboxGroup
      name="reasonsForFailedAttempt"
      label="Reason for failure"
      disabled={disabled}
      options={CaseSchemaOptions.reasonsForFailedAttempt}
    />
  );
};

export default ReasonsForFailedAttempt;
