import { faCaretUp, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortBy } from "lodash";
import React, { MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LabelledIcon from "../components/atoms/LabelledIcon";
import ListCountHeading from "../components/atoms/ListCountHeading";
import Box from "../components/forms/Box";
import UserGroupFormDialog from "../components/groups/UserGroupFormDialog";
import UserGroupRow from "../components/groups/UserGroupRow";
import PageHeader from "../components/pages/PageHeader";
import { SITE_TITLE } from "../helpers/strings";
import { UserGroup } from "../schemas/UserGroupSchema";
import { AppDispatch, RootState } from "../store";
import { fetchBootData, selectUserGroups } from "../store/metadataSlice";

export const TEST_ID_NEW_USER_GROUP_BUTTON = "NewUserGroupButton";
export const TEST_ID_USER_GROUPS_TABLE = "UserGroupsTable";
export const TEST_ID_USER_GROUPS_TABLE_HEADING = "UserGroupsTableHeading";

export enum UserGroupFormMode {
  NEW = "New user group",
  EDIT = "Edit user group",
}

const UserGroups = (): JSX.Element => {
  const [selectedUserGroupId, setSelectedUserGroupId] = useState<string>();
  const [showUserGroupDialog, setShowUserGroupDialog] = useState<boolean>(false);

  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const userGroups = useSelector(selectUserGroups);
  const { status } = useSelector((state: RootState) => state.metadata);

  // Page title
  const pageTitle = "User groups";
  useEffect(() => {
    document.title = `${pageTitle} | ${SITE_TITLE}`;
    dispatch(fetchBootData());
  }, [dispatch]);

  const isLoading = status === "pending";
  const userGroupCount = userGroups.length;

  // Get the user group object for the selected user group ID
  const selectedUserGroup: UserGroup | undefined = userGroups.find(
    ({ userGroupId }) => userGroupId === selectedUserGroupId
  );

  const openUserGroupDialog = (e?: MouseEvent, userGroupId?: string): void => {
    e?.preventDefault();
    setSelectedUserGroupId(userGroupId);
    setShowUserGroupDialog(true);
  };

  const closeUserGroupDialog = (): void => {
    setSelectedUserGroupId(undefined);
    setShowUserGroupDialog(false);
    dispatch(fetchBootData());
  };

  return (
    <>
      <div className="container">
        <PageHeader title={pageTitle} subtitle="Portal user groups and LIMS mappings" />
        <div className="columns is-vcentered is-mobile">
          <div className="column">
            <ListCountHeading
              noun="group"
              count={userGroupCount}
              isLoading={isLoading}
              testId={TEST_ID_USER_GROUPS_TABLE_HEADING}
            />
          </div>
          <div className="column has-text-right">
            <button
              type="button"
              className="button is-primary"
              data-testid={TEST_ID_NEW_USER_GROUP_BUTTON}
              onClick={openUserGroupDialog}
            >
              <LabelledIcon icon={faPlus} label="New group" />
            </button>
          </div>
        </div>
        {userGroupCount > 0 && (
          <Box className="table-container">
            <table className="table is-fullwidth" data-testid={TEST_ID_USER_GROUPS_TABLE}>
              <thead>
                <tr>
                  <th>
                    Name <FontAwesomeIcon icon={faCaretUp} className="ml-1" />
                  </th>
                  <th>LIMS clinician / case origin</th>
                  <th>Portal activation date</th>
                  <th>MFA policy</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {sortBy(userGroups, "userGroupName").map((group) => {
                  return (
                    <UserGroupRow
                      userGroup={group}
                      key={group.userGroupId}
                      openUserGroupDialog={openUserGroupDialog}
                    />
                  );
                })}
              </tbody>
            </table>
          </Box>
        )}
      </div>
      {showUserGroupDialog && (
        <UserGroupFormDialog
          selectedUserGroup={selectedUserGroup}
          closeUserGroupDialog={closeUserGroupDialog}
        />
      )}
    </>
  );
};

export default UserGroups;
