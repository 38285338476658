import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { getPatientNameFromInitials } from "../../helpers/strings";
import { CaseUiData } from "../schema/CaseUiSchema";
import { Answer } from "../schema/FormAnswers";

const useFieldModifiers = () => {
  const { getValues, setValue } = useFormContext<CaseUiData>();

  // Set patient first and last names from their initials for BEST4 forms
  const setPatientNameFromInitials = useCallback(() => {
    const initials = getValues("patientInitials").trim();
    const { firstName, surname } = getPatientNameFromInitials(initials);
    setValue("patientFirstName", firstName);
    setValue("patientSurname", surname);
  }, [getValues, setValue]);

  // Clear "Not provided" patient identifier when switching to a BEST4 form
  const clearPatientIdentifierIfNotProvided = useCallback(() => {
    if (getValues("patientIdentifier") === Answer.UNANSWERED) {
      setValue("patientIdentifier", "");
    }
  }, [getValues, setValue]);

  const updateFailedAttemptFields = useCallback(() => {
    const { NONE, ONE, TWO } = Answer;
    const { numberOfFailedAttempts } = getValues();

    // Coerce "Number of failed attempts: 2" to "Was there a failed attempt? Yes"
    if (numberOfFailedAttempts === TWO) {
      setValue("numberOfFailedAttempts", ONE);
    }

    // Reset related fields based on whether or not there was a failed attempt
    if (numberOfFailedAttempts === NONE) {
      setValue("deviceIdForFailedAttempt", null);
      setValue("reasonsForFailedAttempt", null);
    } else {
      setValue("deviceIdForFailedAttempt", "");
      setValue("reasonsForFailedAttempt", []);
    }
  }, [getValues, setValue]);

  return {
    setPatientNameFromInitials,
    clearPatientIdentifierIfNotProvided,
    updateFailedAttemptFields,
  };
};

export default useFieldModifiers;
