import classNames from "classnames";
import React from "react";
import { FieldValues, Path, useFormContext } from "react-hook-form";

interface CheckboxGroupProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  options: string[];
  indented?: boolean;
  disabled?: boolean;
}

const CheckboxGroup = <T extends FieldValues>({
  name,
  label,
  options,
  indented = false,
  disabled = false,
}: CheckboxGroupProps<T>): JSX.Element => {
  const { register } = useFormContext<T>();

  return (
    <div
      role="group"
      aria-labelledby={`${name}Label`}
      className={classNames("field mb-5", { indented, disabled })}
    >
      <h4 id={`${name}Label`} className="label is-size-6 has-text-weight-bold">
        {label}
      </h4>
      <div className="control">
        {options.map((option) => {
          return (
            <label
              key={option}
              className={classNames("checkbox", { "has-text-grey-light": disabled })}
            >
              <input
                type="checkbox"
                value={option}
                disabled={disabled}
                {...register(name)}
              />
              {option}
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default CheckboxGroup;
