import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortBy } from "lodash";
import React, { MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ListCountHeading from "../components/atoms/ListCountHeading";
import Box from "../components/forms/Box";
import PageHeader from "../components/pages/PageHeader";
import SpecialRequestDialog from "../components/specials/SpecialRequestDialog";
import SpecialRequestRow from "../components/specials/SpecialRequestRow";
import { SITE_TITLE } from "../helpers/strings";
import { Special } from "../schemas/SpecialRequestSchema";
import { AppDispatch, RootState } from "../store";
import { fetchSpecials } from "../store/specialsListSlice";
import ErrorPage from "./ErrorPage";

export const TEST_ID_SPECIALS_LIST_TITLE = "SpecialsListTitle";
export const TEST_ID_SPECIALS_LIST_TABLE = "SpecialsTable";

const SpecialsList = (): JSX.Element => {
  const [selectedSpecialId, setSelectedSpecialId] = useState<string>();

  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const { status, error, results, total } = useSelector(
    (state: RootState) => state.specialsList
  );

  // Page title
  const pageTitle = "Specials";
  useEffect(() => {
    document.title = `${pageTitle} | ${SITE_TITLE}`;
    dispatch(fetchSpecials());
  }, [dispatch]);

  const isError = status === "failed";
  const isLoading = status === "pending";
  const isLoaded = status === "succeeded";
  const showResults = (isLoading || isLoaded) && total > 0;

  // Get the object for the selected special request ID
  const selectedSpecial: Special | undefined = results.find(
    ({ id }) => id === selectedSpecialId
  );

  const openDialog = (e: MouseEvent, id: string): void => {
    e.preventDefault();
    setSelectedSpecialId(id);
  };

  const closeDialog = (): void => {
    setSelectedSpecialId(undefined);
    dispatch(fetchSpecials());
  };

  if (isError) {
    return <ErrorPage title={error?.error} subtitle={error?.msg} />;
  }

  return (
    <>
      <div className="container">
        <PageHeader title={pageTitle} subtitle="Manage lab special requests" />
        <ListCountHeading
          noun="request"
          count={total}
          isLoading={isLoading}
          testId={TEST_ID_SPECIALS_LIST_TITLE}
        />
        {showResults && (
          <Box className="table-container">
            <table
              className="table is-fullwidth"
              data-testid={TEST_ID_SPECIALS_LIST_TABLE}
            >
              <thead>
                <tr>
                  <th>Case</th>
                  <th>
                    Request date <FontAwesomeIcon icon={faCaretUp} className="ml-1" />
                  </th>
                  <th>Block</th>
                  <th>Details</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {sortBy(results, "dateRequested").map((special) => (
                  <SpecialRequestRow
                    key={special.id}
                    special={special}
                    openDialog={openDialog}
                  />
                ))}
              </tbody>
            </table>
          </Box>
        )}
      </div>
      {selectedSpecial && (
        <SpecialRequestDialog special={selectedSpecial} closeDialog={closeDialog} />
      )}
    </>
  );
};

export default SpecialsList;
